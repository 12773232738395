@font-face {
  font-family: 'Medium';
  src: url('../assets/font/Inter-Medium.ttf');
}

@font-face {
  font-family: 'Bold';
  src: url('../assets/font/Inter-Bold.ttf');
}

@font-face {
  font-family: 'Light';
  src: url('../assets/font/Inter-Light.ttf');
}