html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: 'MARKER-FELT';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pointer {
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #c7c7c7;
}

::-webkit-scrollbar-track {
  border-radius: 6px;
  background: #eaeef1;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #cacaca;
  font-size: 12px;
  font-family: 'MT-REGULAR';
}